import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

export class FwFooter extends LitElement {
  
  static get properties() {
    return {
        profile: { type: Object },
        base: { type: String },
        content: { type: String }
    };  
  }

  async fetchFooter() {
            const url = this.base + '/fw/api/content/footer';
            const response = await fetch(url, {
                credentials: 'include'
            });
            if (response.status == 200) {
                const text = await response.text();
                if (text.indexOf("<") == 0) { 
                    this.content = text; 
                } else {
                    this.content = '';
                }         
            }
           
  }
  
  constructor() {
    super();
    this.base = '';
    this.profile = {};
    this.authenticatedEvent = this.authenticatedEvent.bind(this);
  }
  
 connectedCallback() {
    super.connectedCallback();
    document.addEventListener("_FW_AUTHENTICATED", this.authenticatedEvent); 
    this.fetchFooter();
    // Because the fw-auth component is independently placed from this component
    // it may have fired the authentication event BEFORE this component has 
    // initialized and placed its listener.  So, try to load the data anyway.
    try { 
        this.profile = document.querySelector('fw-auth').getData().getProfile(); 
    } catch (x) {}
  }
  
  disconnectedCallback() {
    document.removeEventListener("_FW_AUTHENTICATED", this.authenticatedEvent);    
    super.disconnectedCallback();
  }
  
  async authenticatedEvent(e) { 
      this.profile = document.querySelector('fw-auth').getData().getProfile();
  };
  
  // Masquerade URL should point to the proper host (go/go-dev) and correct
  // service URL and always redirect to the root of FuquaWorld (/fw).
  getLeaveMasqueradeURL() {
      if (this.base.length > 1) {
         return this.base + "/auth/invalidate?service=https://go.fuqua.duke.edu/fw";
      } else {
        if (window.location.href.indexOf("https://go.fuqua") > -1) {
          return "/auth/invalidate?service=https://go.fuqua.duke.edu/fw";
        } else {
          return "/auth/invalidate?service=https://go-dev.fuqua.duke.edu/fw";
        }          
      }

  };
  
  render() {

	return  html`
                    ${this.content ? html`${unsafeHTML(this.content)}`: html``}                            
                    ${this.profile.proxied ? html`
                    <div class="fixed-bottom masquerade">
                        <span>Viewing FuquaWorld as ${this.profile.cn}</span>
                        <button onclick="window.location.href='${this.getLeaveMasqueradeURL()}'" class="fw--btn">Leave View</button>
                    </div>
                    `: html``}
                `;

  }
  
  createRenderRoot() {
	return this;
  }

}
if (!customElements.get('fw-footer')) customElements.define('fw-footer', FwFooter);
